import React from 'react';

import '../../Style/Loading.css';

const Loading = () => {

  return (
    <div className="LoadingLayout Page">
        <h3>Chargement...</h3>
    </div>
  );
}

export default Loading;